import restaurant from "./images/rascruce.jpg";
import img_card from "./images/b.png";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/img6.jpg";
import imgheaderm from "./images/img6.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";

{/*IvanGrozniiVtoroi@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Иван",
        mireasa: "Татьяна",
        data: "30 Июля 2022",
        data_confirmare: "25 июля 2022",
        savedata: "Приглашаем Вас!",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "IvanGrozniiVtoroi@gmail.com", 
       tel: "+373 79 55 65 75",
       phone: "tel:+37379556575",
       viber: "viber://chat?number=%2B37379556575",
       whatsapp: "https://wa.me/+37379556575",
       messenger: "https://www.messenger.com/t/InvitatiiWeb",
       tel1: "+373xxx",
       phone1: "tel:+XXX",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+XXX",
       messenger1: "https://www.messenger.com/t/xxx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Ресторан",
            name: "La Răscruce",
            data: "30 июля 2022, суббота, 12:00 ч.",
            adress: "ул. Куза-Воды 30/1, Кишинёв",
            harta: "https://goo.gl/maps/Csc9EzZagmPwULSS6",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2722.1372844503453!2d28.854075600000005!3d46.9786381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c979342d1e9f25%3A0x28f3a50b7c2feacd!2zU3RyLiBDdXphIFZvZGEsIDMwLzEsIGV0LiAyLCBTdHJhZGEgQ3V6YSBWb2TEgyAzMC8xLCBDaGnImWluxIN1IDIwNjA!5e0!3m2!1sru!2s!4v1658087185522!5m2!1sru!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "Уважаемые гости! ",
            message: "Приглашаем вас на самое главное торжество нашей жизни - венчание!  ",
            message1: "Нам будет очень приятно, если вы будете свидетелями этого прекрасного события и разделите с нами нашу радость!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Самые близкие и родные люди.",
            message: "С кем мы напишем нашу историю.",
            nasii: "С БЛАГОСЛОВЕНИЕМ И ПОДДЕРЖКОЙ НАНАШЕЙ",
            nasii_nume: "Сергей и Ольга",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "\" Любить — это не значит смотреть друг на друга , любить — значит вместе смотреть в одном направлении .\"",
            message: "– Антуан де Сент-Экзюпери",
        }
    ],

}

export default data;